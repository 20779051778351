import ToggleHeaderNav from './modules/ToggleHeaderNav.js';
import ContentToggle from './modules/ContentToggle.js';

document.addEventListener("DOMContentLoaded", function() {
  for (const e of document.querySelectorAll('[data-toggle-nav]')) {
    new ToggleHeaderNav(e);
  }

  for (const e of document.querySelectorAll('[data-collapsable]')) {
    new ContentToggle(e);
  }
});
