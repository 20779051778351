class ContentToggle {
  constructor(el) {
    this._toggle(el);
  }

  _toggle(el) {
    var _content = $(el).find('[data-collapsable="content"]');
    var _button = $(el).find('[data-collapsable="button"]');

    _button.on('click', function() {
      _button.toggleClass('is-active');

      _content.slideToggle('slow', function() {
        $('html, body').animate({
          scrollTop: _content.offset().top
        }, 400);
      });
    });
  }
}

export default ContentToggle;
